<template>
  <v-container id="buying" tag="section" class="container">
    <section id="buy" class="background">
      <v-container class="text-center">
        <div id="wrapper1">
          <div class="text1">
            <h1 class="header">Ready to Make a Move?</h1>
            </br>
            <div class="makeMove">
              <p>
                Are you looking to purchase your first home, dreaming of a
                larger space, or eyeing an investment opportunity? Let me guide
                you through the A to Z of purchasing your new home.
              </p>
              <p>
                Building authentic connections with my clients is at the heart
                of what I do. I leverage my extensive knowledge to find you a
                home that aligns with your needs, all while ensuring you are
                fully informed every step of the way.
              </p>
            </div>
          </div>
          <div class="image1">
            <v-img
              :src="require(`@/assets/images/mahsa_buying.JPG`)"
              height="100%"
            />
          </div>
        </div>
      </v-container>
    </section>
    <section id="buy" class="background">
      <v-container class="services">
        <h1 class="servicesTitle">My services at a glance</h1>
        <v-row no-gutters>
          <v-col v-for="n in 6" :key="n" cols="12" sm="4">
            <v-card class="pa-2 cards" flat color="transparent" tile>
              <v-img
                class="icon"
                :src="require(`@/assets/icons/${services[n - 1].icon}`)"
                max-height="100"
                max-width="100"
              />
              <h2>{{ services[n - 1].title }}</h2>
              <p>{{ services[n - 1].description }}</p>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <subscribe />

    <social />
  </v-container>
</template>

<script>
export default {
  name: "BuyingComponent",

  components: {
    Social: () => import("@/components/home/Social"),
    Subscribe: () => import("@/components/home/Subscribe"),
  },

  data: () => ({
    services: [
      {
        title: "Consultation",
        icon: "consultation.JPG",
        description:
          "We'll start with a meeting to learn more about each other and to discuss your home-buying needs. I'll guide you through the entire process of buying a home, including the financial aspects, closing procedures, and tips for saving you thousands along the way.",
      },
      {
        title: "Showings",
        icon: "showings.JPG",
        description:
          "Following our initial meeting, I'll share with you the latest home listings, giving us the opportunity to view them ahead of others. Together, we'll embark on an in-depth, personal tour of each property, during which I'll provide you with all the necessary details to make a well-informed choice.",
      },
      {
        title: "Prospecting and Networking",
        icon: "networking.JPG",
        description:
          "The perfect home for you might not be listed for sale yet. That's where my role becomes crucial. I utilize buyer letters, tap into my exclusive networks, and employ other strategies to uncover off-market properties that meet your criteria, ensuring you don't miss out on hidden gems.",
      },
      {
        title: "Negotiation and Contract",
        icon: "contract.JPG",
        description:
          "Once you've chosen your ideal home, I'll navigate the negotiations for you. From price, inspections to financing, closing, and any tasks post-offer acceptance, I'll ensure each step is in your best interest. Our goal is to make sure every term of the deal favours you.",
      },
      {
        title: "Trusted Network of Professionals",
        icon: "network.JPG",
        description:
          "I will connect you with trusted professionals that will help the deal go as seamless as possible. These include mortgage professionals, inspectors, lawyers, contractors and more.",
      },
      {
        title: "Seamless Process",
        icon: "fun.JPG",
        description:
          "I understand that buying a home can be overwhelming, but I'm here to simplify it and make it a positive experience. I value building lasting, meaningful relationships with my clients—enough to hope for a housewarming invite!",
      },
    ],
  }),
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap");

.container {
  max-width: 100%;
}

.header {
  font-size: 2.5em;
}

.makeMove {
  line-height: 2.5;
}

#wrapper1 {
  height: 90%;
  width: 1200px;
  align-content: center;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}

.image1 {
  width: 50%;
}

.text1 {
  height: 50%;
  width: 50%;
  line-height: 1.4;
  font-size: 18px;
  text-align: left;
  letter-spacing: 0em;
  font-weight: 350;
  font-style: normal;
  padding-right: 25px;
}

.services {
  width: 70%;
  background-color: #F9F9F9;
}

.servicesTitle {
  font-size: 45px;
}

.icon {
  margin-left: -20px;
}

.background {
  background-color: #ffffff;
}

@media only screen and (max-width: 600px) {
  #wrapper1 {
    width: 100%;
  }

  .header {
    font-size: 1.75em;
  }

  .image1 {
    height: 30em;
    width: 100%;
    padding-bottom: 15px;
  }

  .text1 {
    height: 20%;
    width: 100%;
  }

  .services {
    width: 100%;
  }

  .servicesTitle {
    font-size: 30px;
  }
}
</style>
